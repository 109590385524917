app.factory('displayService', ['$q', '$rootScope', 'broadcastService', '$http', 'authService', '$cookies', 'signalRService', 'localStorageService', '$state', '$ocLazyLoad', function ($q, $rootScope, broadcastService, $http, authService, $cookies, signalRService, localStorageService, $state, $ocLazyLoad)
{
    //to do
    // 1. _monkeyPatchInOldVariables
    // 2. loadAccessInformation filter or remove all old data, bookmark as well
    // 4. real time refresh?

    //load everything related to display
    //menu, grid, label, message, report, autocomplete list, and etc
    let displayServiceFactory = {};

    let DisplayInformation = {
        ReportMenu: [],
        FormMenu: [],
        GridMenu: [],
        DashboardMenu: [],
        Message: [],
        Label: [],
        Country: "M",
        UserPhotoUrl: "",
        QCDashboardMenu: [],
        SubscribedServices: [],
        MenuModule: [],
        CustomViewMenu: [],
        NoImageUrl: "",
        CRChequeTemplateUrl: ""
    };

    let AccessInformation = {
        Bookmark: [],
        Autocomplete: []
    };

    let _initiateOfflineDB = function ()
    {
        let authData = authService.authentication;
        if (authData.token)
        {
            //check for support
            if (!('indexedDB' in window) || !($.browser.webkit === true))
            {
                console.log('This browser doesn\'t support IndexedDB');
                return;
            }

            $rootScope.dbPromise = idb.open('OfflineFirstDB(' + authData.userID + ' - ' + authData.clientID + ')', 1, function (upgradeDb)
            {
                if (!upgradeDb.objectStoreNames.contains('dailyatrenter'), { keyPath: 'id' })
                {
                    upgradeDb.createObjectStore('dailyatrenter', {
                        keyPath: 'id',
                        autoIncrement: true
                    });
                }

                if (!upgradeDb.objectStoreNames.contains('ffbharenter'), { keyPath: 'id' })
                {
                    upgradeDb.createObjectStore('ffbharenter', {
                        keyPath: 'id',
                        autoIncrement: true
                    });
                }
            });
        }
    };

    displayServiceFactory.initialize = function ()
    {
        let defer = $q.defer();

        signalRService.refreshSession().then(function ()
        {
            _initiateOfflineDB();
            defer.resolve();
        }, function ()
        {
            authService.logOut();
            defer.reject();
            $state.go('login');
        });

        return defer.promise;
    };

    let _monkeyPatchInOldVariables = function ()
    {
        //temporary measure as need time to patch out all form using old variables

        $rootScope.label = DisplayInformation.Label.deepClone();
        $rootScope.msg = DisplayInformation.Message.deepClone();
    };

    //this is to preload dashboard jsfile
    let _loadDashboardJsFile = function (DashboardMenu)
    {
        let defer = $q.defer();
        let DirectiveToLoad = [];

        DashboardMenu.forEach(function (item)
        {
            if (!$ocLazyLoad.isLoaded(item.UniqueName))
            {
                DirectiveToLoad.push($ocLazyLoad.load({
                    name: item.UniqueName,
                    files: [item.JsControllerUrl]
                }));
            }
        });

        $q.all(DirectiveToLoad).then(function ()
        {
            defer.resolve();
        }, function ()
        {
            //non critical stuff, let it go through
            defer.resolve();
        });

        return defer.promise;
    };

    //this is required as locally we wont start these module, need to fake its success
    let _loadOptionalInformation = function (type)
    {
        let defer = $q.defer();

        switch (type)
        {
            case "Report":
                $http.get($rootScope.reportOdataUrl + "ReportManagementDet?isUserPerm=true&$format=json", { timeout: 20000, retry: false }).then(function (data)
                {
                    if (typeof data !== "undefined" && typeof data.data !== "undefined" && typeof data.data.value !== "undefined")
                        defer.resolve(data.data.value);
                    else
                        defer.resolve([]);
                }, function ()
                {
                    defer.resolve([]);
                });
                break;
            case "Profile":
                $http.get($rootScope.dashboardWebApiurl + "LastLogin", { timeout: 10000, retry: false }).then(function (data)
                {
                    if (typeof data !== "undefined" && typeof data.data !== "undefined" && typeof data.data.UserPhotoUrl !== "undefined")
                        defer.resolve(data.data.UserPhotoUrl);
                    else
                        defer.resolve("");
                }, function ()
                {
                    defer.resolve("");
                });
                break;
            case "NoImageUrl":
                $http.get($rootScope.masterWebApiUrl + "ImportData/GetNoImage", { timeout: 10000, retry: false }).then(function (data)
                {
                    if (typeof data !== "undefined" && typeof data.data !== "undefined")
                        defer.resolve(JSON.parse(data.data));
                    else
                        defer.resolve("");
                }, function ()
                {
                    defer.resolve("");
                });
                break;
            case "CRChequeTemplateUrl":
                $http.get($rootScope.masterWebApiUrl + "ImportData/GetCRChequeTemplate", { timeout: 10000, retry: false }).then(function (data)
                {
                    if (typeof data !== "undefined" && typeof data.data !== "undefined")
                        defer.resolve(JSON.parse(data.data));
                    else
                        defer.resolve("");
                }, function ()
                {
                    defer.resolve("");
                });
                break;
            case "Dashboard":
                $http.get($rootScope.dashboardOdataUrl + "DBoardPrefDet?dummy=1", { timeout: 10000, retry: false }).then(function (data)
                {
                    if (typeof data !== "undefined" && typeof data.data !== "undefined" && typeof data.data.value !== "undefined")
                    {
                        _loadDashboardJsFile(data.data.value).then(function ()
                        {
                            defer.resolve(data.data.value);
                        }, function ()
                        {
                            defer.resolve(data.data.value);
                        });
                    }
                    else
                    {
                        defer.resolve([]);
                    }
                }, function ()
                {
                    defer.resolve([]);
                });
                break;
            case "CustomView":
                $http.get($rootScope.masterWebApiUrl + "CustomRptWiz/GetRptTemplate", { timeout: 10000, retry: false }).then(function (data)
                {
                    if (typeof data !== "undefined" && typeof data.data !== "undefined" && Array.isArray(data.data))
                    {
                        function groupBy(array, f)
                        {
                            var groups = {};
                            array.forEach(function (o)
                            {
                                var group = JSON.stringify(f(o));
                                groups[group] = groups[group] || [];
                                groups[group].push(o);
                            });
                            return Object.keys(groups).map(function (group)
                            {
                                return groups[group];
                            })
                        }

                        data = broadcastService.parseStringToDecimal(data.data);

                        const tempRptTemplate = [];
                        const tempModule = [];
                        const chkData = groupBy(data, function (item)
                        {
                            return [item.SPName, item.ModuleKey];
                        }).map(function (item)
                        {
                            return new Object({
                                SPName: item[0].SPName,
                                SPDesc: item[0].SPDesc,
                                SPDispName: item[0].SPDispName,
                                ModuleKey: item[0].ModuleKey,
                                ModuleName: item[0].ModuleName,
                                MenuKey: item[0].MenuKey,
                            });
                        })
                        const map = new Map();
                        for (const item of chkData)
                        {
                            if (!map.has(item.SPName))
                            {
                                map.set(item.SPName, true);    // set any value to Map
                                tempRptTemplate.push({
                                    SPName: item.SPName,
                                    SPDesc: item.SPDesc,
                                    SPDispName: item.SPDispName,
                                    ModuleKey: item.ModuleKey,
                                    MenuKey: item.MenuKey,
                                    collapse: true,
                                    active: false,
                                });
                            }
                            else
                            {
                                for (var i = 0; i < tempRptTemplate.length; i++)
                                {
                                    if (item.SPName == tempRptTemplate[i].SPName && item.ModuleKey != tempRptTemplate[i].ModuleKey)
                                    {
                                        tempRptTemplate.push({
                                            SPName: item.SPName,
                                            SPDesc: item.SPDesc,
                                            SPDispName: item.SPDispName,
                                            ModuleKey: item.ModuleKey,
                                            MenuKey: item.MenuKey,
                                            collapse: true,
                                            active: false,
                                        });
                                    }
                                }
                            }
                            if (!map.has(item.ModuleName))
                            {
                                map.set(item.ModuleName, true);
                                tempModule.push({
                                    ModuleName: item.ModuleName,
                                    ModuleKey: item.ModuleKey
                                });
                            }
                        }

                        for (var i = 0; i < tempModule.length; i++)
                        {
                            let aimei = tempRptTemplate.filter(function (item)
                            {
                                if (item.ModuleKey == tempModule[i].ModuleKey)
                                {
                                    return true;
                                }
                                else
                                {
                                    return false;
                                }
                            });
                            tempModule[i].TotalReport = aimei.length;
                            tempModule[i].reportData = aimei;
                        }

                        defer.resolve(tempModule);
                    }
                    else
                    {
                        defer.resolve([]);
                    }
                }, function ()
                {
                    defer.resolve([]);
                });
                break;
            default:
                // =D
                defer.reject();
        }

        return defer.promise;
    };

    //load display information
    displayServiceFactory.loadDisplayInformation = function ()
    {
        let defer = $q.defer();

        //clear DisplayInformation
        DisplayInformation.ReportMenu = [];
        DisplayInformation.FormMenu = [];
        DisplayInformation.GridMenu = [];
        DisplayInformation.DashboardMenu = [];
        DisplayInformation.QCDashboardMenu = [];
        DisplayInformation.Message = [];
        DisplayInformation.Label = [];
        DisplayInformation.Country = "M";
        DisplayInformation.UserPhotoUrl = "";
        DisplayInformation.SubscribedServices = [];
        DisplayInformation.MenuModule = [];
        DisplayInformation.CustomViewMenu = [];
        DisplayInformation.NoImageUrl = "";
        DisplayInformation.CRChequeTemplateUrl = "";

        //add at last index of the $q.all parameter array to avoid screwing up index
        $q.all([
            _loadOptionalInformation("Report"),
            broadcastService.getUIInformation(),
            Promise.resolve([]), //to avoid screw up index :D
            _loadOptionalInformation("Dashboard"),
            _loadOptionalInformation("Profile"),
            $http.get($rootScope.masterWebApiUrl + "CRegisteredSubscribedService"),
            $http.get($rootScope.masterWebApiUrl + "MenuModule"),
            _loadOptionalInformation("CustomView"),
            _loadOptionalInformation("NoImageUrl"),
            _loadOptionalInformation("CRChequeTemplateUrl")
        ]).then(function (data)
        {
            //assign display info
            DisplayInformation.Country = data[1][0].contains("M") ? "M" : data[1][0].contains("I") ? "I" : data[1][0];
            DisplayInformation.GridMenu = data[1][4];
            DisplayInformation.FormMenu = data[1][3];
            DisplayInformation.ReportMenu = data[0];
            DisplayInformation.DashboardMenu = data[3];
            DisplayInformation.UserPhotoUrl = data[4];
            DisplayInformation.SubscribedServices = data[5].data;
            DisplayInformation.MenuModule = data[6].data;
            DisplayInformation.CustomViewMenu = data[7];
            DisplayInformation.NoImageUrl = data[8];
            DisplayInformation.CRChequeTemplateUrl = data[9];

            let RawMessageData = data[1][2];
            let RawLabelData = data[1][1];

            let label = [];
            let msg = [];

            RawLabelData.forEach(function (item)
            {
                if (typeof label[item.LabelKey] === 'undefined')
                {
                    label[item.LabelKey] = [];
                    if (typeof label[item.LabelKey][item.LangKey] === 'undefined')
                    {
                        label[item.LabelKey][item.LangKey] = {};
                    }
                }
                label[item.LabelKey][item.LangKey] = item;
            });

            RawMessageData.forEach(function (item)
            {
                if (typeof msg[item.MsgKey] === 'undefined')
                {
                    msg[item.MsgKey] = [];
                    if (typeof msg[item.MsgKey][item.LangKey] === 'undefined')
                    {
                        msg[item.MsgKey][item.LangKey] = {};
                    }
                }
                msg[item.MsgKey][item.LangKey] = item;
            });

            //patch in missing keys to avoid null reference causing rainbow~~
            let labellen = label.length + 100;
            for (var ik = 0; ik < labellen; ik++)
            {
                if (!Array.isArray(label[ik]))
                {
                    label[ik] = [];

                    //to keep the array valid
                    label[ik][0] = {
                        ClientKey: 1,
                        LabelDesc: "",
                        LabelKey: ik,
                        LangKey: 0
                    };

                    label[ik][1] = {
                        ClientKey: 1,
                        LabelDesc: "",
                        LabelKey: ik,
                        LangKey: 1
                    };

                    label[ik][2] = {
                        ClientKey: 1,
                        LabelDesc: "",
                        LabelKey: ik,
                        LangKey: 2
                    };

                    label[ik][3] = {
                        ClientKey: 1,
                        LabelDesc: "",
                        LabelKey: ik,
                        LangKey: 3
                    };
                }
                else
                {
                    if (typeof label[ik][0] === "undefined")
                    {
                        label[ik][0] = {
                            ClientKey: 1,
                            LabelDesc: "",
                            LabelKey: ik,
                            LangKey: 0
                        };
                    }

                    if (typeof label[ik][1] === "undefined")
                    {
                        label[ik][1] = {
                            ClientKey: 1,
                            LabelDesc: "",
                            LabelKey: ik,
                            LangKey: 1
                        };
                    }

                    if (typeof label[ik][2] === "undefined")
                    {
                        label[ik][2] = {
                            ClientKey: 1,
                            LabelDesc: "",
                            LabelKey: ik,
                            LangKey: 2
                        };
                    }

                    if (typeof label[ik][3] === "undefined")
                    {
                        label[ik][3] = {
                            ClientKey: 1,
                            LabelDesc: "",
                            LabelKey: ik,
                            LangKey: 3
                        };
                    }
                }
            }

            let msglen = msg.length + 100;
            for (var il = 0; il < msglen; il++)
            {
                if (!Array.isArray(msg[il]))
                {
                    msg[il] = [];

                    //to keep the array valid
                    msg[il][0] = {
                        LangKey: 0,
                        MsgBody: "",
                        MsgHdr: "",
                        MsgIcon: 1,
                        MsgKey: il,
                        MsgType: 1
                    };

                    msg[il][1] = {
                        LangKey: 1,
                        MsgBody: "",
                        MsgHdr: "",
                        MsgIcon: 1,
                        MsgKey: il,
                        MsgType: 1
                    };

                    msg[il][2] = {
                        LangKey: 2,
                        MsgBody: "",
                        MsgHdr: "",
                        MsgIcon: 1,
                        MsgKey: il,
                        MsgType: 1
                    };

                    msg[il][3] = {
                        LangKey: 3,
                        MsgBody: "",
                        MsgHdr: "",
                        MsgIcon: 1,
                        MsgKey: il,
                        MsgType: 1
                    };
                }
                else
                {
                    if (typeof msg[il][0] === "undefined")
                    {
                        msg[il][0] = {
                            LangKey: 0,
                            MsgBody: "",
                            MsgHdr: "",
                            MsgIcon: 1,
                            MsgKey: il,
                            MsgType: 1
                        };
                    }

                    if (typeof msg[il][1] === "undefined")
                    {
                        msg[il][1] = {
                            LangKey: 1,
                            MsgBody: "",
                            MsgHdr: "",
                            MsgIcon: 1,
                            MsgKey: il,
                            MsgType: 1
                        };
                    }

                    if (typeof msg[il][2] === "undefined")
                    {
                        msg[il][2] = {
                            LangKey: 2,
                            MsgBody: "",
                            MsgHdr: "",
                            MsgIcon: 1,
                            MsgKey: il,
                            MsgType: 1
                        };
                    }

                    if (typeof msg[il][3] === "undefined")
                    {
                        msg[il][3] = {
                            LangKey: 3,
                            MsgBody: "",
                            MsgHdr: "",
                            MsgIcon: 1,
                            MsgKey: il,
                            MsgType: 1
                        };
                    }
                }
            }

            DisplayInformation.Message = msg;
            DisplayInformation.Label = label;

            _monkeyPatchInOldVariables();

            defer.resolve();
        }, function ()
        {
            authService.logOut();
            defer.reject();
            if (window.location.href.contains("resetPassword") === false)
            {
                $state.go('login');
            }
        });

        return defer.promise;
    };

    //retrieve display information
    displayServiceFactory.getDisplayInformation = function ()
    {
        return {
            ReportMenu: DisplayInformation.ReportMenu.deepClone(),
            FormMenu: DisplayInformation.FormMenu.deepClone(),
            GridMenu: DisplayInformation.GridMenu.deepClone(),
            DashboardMenu: DisplayInformation.DashboardMenu.deepClone(),
            Message: DisplayInformation.Message.deepClone(),
            Label: DisplayInformation.Label.deepClone(),
            Country: DisplayInformation.Country,
            UserPhotoUrl: DisplayInformation.UserPhotoUrl,
            QCDashboardMenu: DisplayInformation.QCDashboardMenu.deepClone(),
            CustomViewMenu: DisplayInformation.CustomViewMenu.deepClone(),
            NoImageUrl: DisplayInformation.NoImageUrl,
            CRChequeTemplateUrl: DisplayInformation.CRChequeTemplateUrl
        };
    };

    displayServiceFactory.getReportInformation = function (IsEnable, MenuKey)
    {
        //default is true
        if (IsEnable === false)
        {
            if (typeof MenuKey === "number")
            {
                //MenuKey is unique in Menu if language specific, should have only 1 no point filter, use find and return the first as array
                let Menu = DisplayInformation.ReportMenu.find(function (item)
                {
                    return item.MenuKey === MenuKey;
                });

                if (typeof Menu !== "undefined" && Menu != null)
                {
                    //return as array for the sake of consistency
                    return [Menu].deepClone();
                }
                else
                {
                    return [];
                }
            }
            else
            {
                return DisplayInformation.ReportMenu.deepClone();
            }
        }
        else
        {
            if (typeof MenuKey === "number")
            {
                //MenuKey is unique in Menu if language specific, should have only 1 no point filter, use find and return the first as array
                let Menu = DisplayInformation.ReportMenu.find(function (item)
                {
                    return item.MenuKey === MenuKey && item.IsEnable === true && item.UploadedUrl !== "";
                });

                if (typeof Menu !== "undefined" && Menu != null)
                {
                    //return as array for the sake of consistency
                    return [Menu].deepClone();
                }
                else
                {
                    return [];
                }
            }
            else
            {
                return DisplayInformation.ReportMenu.filter(function (itemEnabled) { return itemEnabled.IsEnable === true && itemEnabled.UploadedUrl !== "" }).deepClone();
            }
        }
    };

    displayServiceFactory.getFormInformation = function (isLanguageSpecific, MenuKey)
    {
        if (isLanguageSpecific === true)
        {
            if (typeof MenuKey === "number")
            {
                //MenuKey is unique in Menu if language specific, should have only 1 no point filter, use find and return the first as array
                let Menu = DisplayInformation.FormMenu.find(function (item)
                {
                    return item.MenuKey === MenuKey && (typeof item.LangKey) !== "undefined" && item.LangKey != null && (+item.LangKey) == ($rootScope.userSetting.langKey);
                });

                if (typeof Menu !== "undefined" && Menu != null)
                {
                    //return as array for the sake of consistency
                    return [Menu].deepClone();
                }
                else
                {
                    return [];
                }
            }
            else
            {
                return DisplayInformation.FormMenu.filter(function (item)
                {
                    return (typeof item.LangKey) !== "undefined" && item.LangKey != null && (+item.LangKey) == ($rootScope.userSetting.langKey);
                }).deepClone();
            }
        }
        else
        {
            if (typeof MenuKey === "number")
            {
                return DisplayInformation.FormMenu.filter(function (item)
                {
                    return item.MenuKey === MenuKey;
                }).deepClone();
            }
            else
            {
                return DisplayInformation.FormMenu.deepClone();
            }
        }
    };

    displayServiceFactory.getModuleInformation = function (isLanguageSpecific)
    {
        if (isLanguageSpecific === true)
        {
            return DisplayInformation.FormMenu.filter(function (item)
            {
                return (typeof item.LangKey) !== "undefined" && item.LangKey != null && (+item.LangKey) == ($rootScope.userSetting.langKey);
            }).reduce(function (a, b)
            {
                if (a.findIndex(c => c.ModuleKey == b.ModuleKey) > -1)
                    return a;
                else
                    return a.concat(b);
            }, []).deepClone();
        }
        else
        {
            return DisplayInformation.FormMenu.reduce(function (a, b)
            {
                if (a.findIndex(c => c.ModuleKey == b.ModuleKey) > -1)
                    return a;
                else
                    return a.concat(b);
            }, []).deepClone();
        }
    };

    displayServiceFactory.getGridInformation = function ()
    {
        return DisplayInformation.GridMenu.deepClone();
    };

    displayServiceFactory.getDashboardInformation = async function ()
    {
        return DisplayInformation.DashboardMenu.deepClone();
    };

    displayServiceFactory.getQCDashboardInformation = async function ()
    {
        if (DisplayInformation.QCDashboardMenu.length)
            return DisplayInformation.QCDashboardMenu.deepClone();

        const qcDBoardPref = await $http.get($rootScope.dashboardWebApiurl + "QCDBoardPref").catch(e => { console.error("Error getting QCDBoardPref") });
        const data = qcDBoardPref?.data;
        if (typeof data === "undefined")
            return [];

        await _loadDashboardJsFile(data);
        DisplayInformation.QCDashboardMenu = data;

        return DisplayInformation.QCDashboardMenu.deepClone();
    };

    displayServiceFactory.getMessageInformation = function ()
    {
        return DisplayInformation.Message.deepClone();
    };

    displayServiceFactory.getLabelInformation = function ()
    {
        return DisplayInformation.Label.deepClone();
    };

    displayServiceFactory.getCountryInformation = function ()
    {
        return DisplayInformation.Country;
    };

    displayServiceFactory.getProfileInformation = function ()
    {
        return DisplayInformation.UserPhotoUrl;
    };

    displayServiceFactory.getNoImageUrl = function ()
    {
        return DisplayInformation.NoImageUrl;
    };

    displayServiceFactory.getCRChequeTemplate = function ()
    {
        return DisplayInformation.CRChequeTemplateUrl;
    };

    displayServiceFactory.getSubscribedServices = function ()
    {
        return DisplayInformation.SubscribedServices.deepClone();
    }

    displayServiceFactory.getMenuModule = function ()
    {
        return DisplayInformation.MenuModule.deepClone();
    }

    displayServiceFactory.getCustomViewMenu = function ()
    {
        return DisplayInformation.CustomViewMenu.deepClone();
    }

    displayServiceFactory.loadAccessInformation = function ()
    {
        let defer = $q.defer();

        //clear AccessInformation
        AccessInformation.Bookmark = [];
        AccessInformation.Autocomplete = [];

        $q.all([
            $http.get($rootScope.masterWebApiUrl + "Bookmark")
        ]).then(function (data)
        {
            data[0].data.forEach(function (item)
            {
                //temporary filter out old data first. will patch eventually
                if (typeof item.MenuKey != "undefined" && item.MenuKey != null && item.MenuKey > 0)
                {
                    if (AccessInformation.Bookmark.findIndex(function (x) { return x.MenuKey === item.MenuKey }) === -1)
                    {
                        let Content = JSON.parse(item.Content);

                        if (typeof Content.Icon !== "string" || Content.Icon === null || Content.Icon === "")
                        {
                            const moduleName = _.get(Content, "ModuleName", "");
                            Content.Icon = _getBoorkmarkIcon(moduleName);
                        }

                        AccessInformation.Bookmark.push({
                            BookmarkKey: item.BookmarkKey,
                            ClientKey: item.ClientKey,
                            UserKey: item.UserKey,
                            Name: item.Name,
                            MenuKey: item.MenuKey,
                            Content: Content,
                            Icon: Content.Icon,
                            Active: item.Active,
                            CreatedBy: item.CreatedBy,
                            CreatedDate: item.CreatedDate,
                            UpdatedBy: item.UpdatedBy,
                            UpdatedDate: item.UpdatedDate,
                            CreatedByCode: item.CreatedByCode,
                            UpdatedByCode: item.UpdatedByCode,
                            RowState: $rootScope.RowState.Unchanged,
                            Posting: false
                        });
                    }
                }
            });

            //assign autocomplete
            AccessInformation.Autocomplete = DisplayInformation.FormMenu.filter(function (item)
            {
                return item.ShowInSearch && (typeof item.LangKey) !== "undefined" && item.LangKey != null && (+item.LangKey) === ($rootScope.userSetting.langKey);
            }).deepClone();

            for (var egg = 0; egg < AccessInformation.Autocomplete.length; egg++)
            {
                //Short Cut label
                AccessInformation.Autocomplete[egg].FormGroup = AccessInformation.Autocomplete[egg].ModuleName;
                AccessInformation.Autocomplete[egg].Icon = "fa-menuwidth " + AccessInformation.Autocomplete[egg].ModuleIconUrl;
            }

            DisplayInformation.ReportMenu.filter(function (itemEnabled) { return itemEnabled.IsEnable === true && itemEnabled.UploadedUrl !== "" }).forEach(function (item)
            {
                AccessInformation.Autocomplete.push({
                    MenuUrl: item.UploadedUrl,
                    DispName: item.RMDisp,
                    MenuName: item.RMDisp,
                    JsControllerUrl: item.RMDisp,
                    FormGroup: "Report",
                    ModuleName: "Report",
                    requestedForm: "reportviewer",
                    Icon: 'fa fa-file-text-o',
                    MenuKey: item.MenuKey
                });
            });

            defer.resolve();
        }, function ()
        {
            defer.resolve();
        });

        return defer.promise;
    };

    //retrieve access information for forms/reports
    displayServiceFactory.getAccessInformation = function ()
    {
        return {
            Bookmark: AccessInformation.Bookmark.filter(function (xyz) { return xyz.RowState !== $rootScope.RowState.Deleted; }).deepClone(),
            AccessInformation: AccessInformation.Autocomplete.deepClone()
        };
    };

    displayServiceFactory.getBookmarkInformation = function (MenuKey)
    {
        if (typeof MenuKey === "number")
        {
            //MenuKey is unique in bookmark, should have only 1 no point filter, use find and return the first as array
            let Bookmark = AccessInformation.Bookmark.find(function (xyz) { return xyz.RowState !== $rootScope.RowState.Deleted && xyz.MenuKey === MenuKey; });

            if (typeof Bookmark !== "undefined" && Bookmark != null)
            {
                //return as array for the sake of consistency
                return [Bookmark].deepClone();
            }
            else
            {
                return [];
            }
        }
        else
        {
            return AccessInformation.Bookmark.filter(function (xyz) { return xyz.RowState !== $rootScope.RowState.Deleted; }).deepClone();
        }
    };

    displayServiceFactory.getAutocompleteInformation = function ()
    {
        return AccessInformation.Autocomplete.deepClone();
    };

    //add/remove access information
    displayServiceFactory.updateBookmark = function (requestItem)
    {
        //need to standardise item, next phase
        if (typeof requestItem !== "undefined" && requestItem !== null)
        {
            if (typeof requestItem.MenuKey !== "number" || (typeof requestItem.MenuKey === "number" && requestItem.MenuKey <= 0))
            {
                //patch back MenuKey until standardise next phase
                let MenuItem = DisplayInformation.FormMenu.filter(function (item)
                {
                    return (typeof item.LangKey) !== "undefined" && item.LangKey != null && (+item.LangKey) == ($rootScope.userSetting.langKey);
                }).find(function (item)
                {
                    return item.ModuleName === requestItem.ModuleName
                        && (item.MenuName === requestItem.name || item.DispName === requestItem.name)
                        && item.MenuUrl.includes(requestItem.url)
                        && item.JsControllerUrl.includes(requestItem.jsUrl);
                });

                if (typeof MenuItem != "undefined" && MenuItem != null)
                {
                    requestItem.MenuKey = MenuItem.MenuKey;
                    requestItem.ModuleName = MenuItem.ModuleName;
                    requestItem.name = MenuItem.DispName;
                }
            }
            else
            {
                //patch back MenuKey until standardise next phase
                let MenuItem = DisplayInformation.FormMenu.filter(function (item)
                {
                    return (typeof item.LangKey) !== "undefined" && item.LangKey != null && (+item.LangKey) == ($rootScope.userSetting.langKey);
                }).find(function (item)
                {
                    return item.MenuKey === requestItem.MenuKey;
                });

                if (typeof MenuItem != "undefined" && MenuItem != null)
                {
                    requestItem.ModuleName = MenuItem.ModuleName;
                    requestItem.name = MenuItem.DispName;
                }
            }

            if (typeof requestItem.name !== "string" || requestItem.name === "")
            {
                //patch back name until standardise next phase
                requestItem.name = requestItem.MenuName;
            }

            if (typeof requestItem.MenuKey === "number" && requestItem.MenuKey > 0)
            {
                //if original isBookmarked = true and call this function means want to un-bookmark
                //if original isBookmarked = false and call this function means want to bookmark
                //so need to treat it as terbalik

                if (typeof requestItem.isBookmarked !== "boolean")
                {
                    requestItem.isBookmarked = false;
                }

                if (requestItem.isBookmarked === false)
                {
                    //check if bookmark exists
                    if (AccessInformation.Bookmark.findIndex(function (x) { return x.MenuKey === requestItem.MenuKey && x.RowState !== $rootScope.RowState.Deleted }) === -1)
                    {
                        let Content = JSON.parse(JSON.stringify(requestItem));

                        if (Content.type === "report")
                        {
                            Content.Icon = "fa fa-file-text-o";
                        }
                        else
                        {
                            const moduleName = _.get(requestItem, "ModuleName", "");
                            Content.Icon = _getBoorkmarkIcon(moduleName);
                        }

                        Content.isBookmarked = true;

                        //store it to bookmarked list
                        AccessInformation.Bookmark.push({
                            BookmarkKey: 0,
                            ClientKey: 0,
                            UserKey: 0,
                            Name: requestItem.name,
                            MenuKey: requestItem.MenuKey,
                            Icon: Content.Icon,
                            Content: Content,
                            Active: true,
                            CreatedBy: -1,
                            CreatedDate: (new Date("1900-01-01")),
                            UpdatedBy: -1,
                            UpdatedDate: (new Date("1900-01-01")),
                            CreatedByCode: "",
                            UpdatedByCode: "",
                            RowState: $rootScope.RowState.Added,
                            Posting: false
                        });

                        broadcastService.broadcast("assignBookmark", {
                            title: requestItem.name,
                            value: true,
                            MenuKey: requestItem.MenuKey,
                            type: requestItem.type
                        });

                        //post later
                        _postBookmark();
                    }
                }
                else if (requestItem.isBookmarked === true)
                {
                    for (var i = 0; i < AccessInformation.Bookmark.length; i++)
                    {
                        if (AccessInformation.Bookmark[i].MenuKey === requestItem.MenuKey && AccessInformation.Bookmark[i].RowState !== $rootScope.RowState.Deleted)
                        {
                            AccessInformation.Bookmark[i].RowState = $rootScope.RowState.Deleted;
                        }
                    }

                    broadcastService.broadcast("assignBookmark", {
                        title: requestItem.name,
                        value: false,
                        MenuKey: requestItem.MenuKey,
                        type: requestItem.type
                    });

                    //post later
                    _postBookmark();
                }
            }
        }

        return AccessInformation.Bookmark.filter(function (xyz) { return xyz.RowState !== $rootScope.RowState.Deleted; }).deepClone();
    };

    function _getBoorkmarkIcon(module)
    {
        if (module.includes("reportFileName"))
            return "fa fa-file-text-o";
        else if (module.includes("Account"))
            return "fa-menuwidth icon-accounts spcrght";
        else if (module.includes("Payroll"))
            return "fa-menuwidth icon-payroll003 spcrght";
        else if (module.includes("Checkroll"))
            return "fa-menuwidth icon-checkroll spcrght";
        else if (module.includes("Budget"))
            return "fa-menuwidth icon-budget spcrght";
        else if (module.includes("Vehicle"))
            return "fa-menuwidth icon-vehicle002 spcrght";
        else if (module.includes("Nursery"))
            return "fa-menuwidth icon-nursery spcrght";
        else if (module.includes("Production"))
            return "fa-menuwidth ico-production";
        else if (module.includes("Weighbridge"))
            return "fa-menuwidth icon-weighbridge003 spcrght";
        else if (module.includes("Lab"))
            return "fa-menuwidth fa fa-flask spcrght";
        else if (module.includes("Master File"))
            return "fa-menuwidth icon-master spcrght";
        else if (module.includes("Dashboard"))
            return "fa-menuwidth icon-dashboard003 spcrght";
        else if (module.includes("Preventive Maintenance"))
            return "fa-menuwidth fa fa-wrench spcrght";
        else if (module.includes("Report"))
            return "fa-menuwidth icon-report002 spcrght";
        else if (module.includes("System Settings"))
            return "fa-menuwidth icon-settings02 spcrght";
        else if (module.includes("Agronomy & Geographic"))
            return "fa-menuwidth fa fa-globe spcrght";
        else if (module.includes("Map"))
            return "fa-menuwidth fa fa-globe spcrght";
        else if (module.includes("Resources"))
            return "fa-menuwidth icon-file-o spcrght";
        else if (module.includes("Administration"))
            return "fa-menuwidth fa fa-cogs spcrght";
        else if (module.includes("Oil Mill Production"))
            return "fa-menuwidth icon-factory01 spcrght";
        else if (module.includes("Fixed Asset"))
            return "fa-menuwidth icon-fixedasset02";
        else if (module.includes("Marketing & Contract"))
            return "fa-menuwidth icon-budget04 spcrght";
        else if (module.includes("FFB Procurement"))
            return "fa-menuwidth icon-ffb spcrght";
        else if (module.includes("Quarto Connect"))
            return "fa-menuwidth fa fa-tablet font18 spcrght";
        else if (module.includes("Analytics"))
            return "fa-menuwidth fa fa-area-chart spcrght";
        else if (module.includes("Contractor Management"))
            return "fa-menuwidth icon-checkroll spcrght";
        else if (module.includes("Farm"))
            return "fa-menuwidth fa fa-leaf spcrght";
        else if (module.includes("Sales & Distribution"))
            return "fa-menuwidth fa fa-truck spcrght";
        else if (module.includes("Inventory"))
            return "fa-menuwidth icon-inventory02 spcrght";
        else if (module.includes("Procurement"))
            return "fa-menuwidth icon-procurement003 spcrght";

        else
            return "fa-menuwidth icon-checkroll spcrght";
    }

    function _postBookmark()
    {
        //gonna change to chathub later
        AccessInformation.Bookmark.forEach(function (bookmark, index)
        {
            let thisBookmark = JSON.parse(JSON.stringify(bookmark));

            if (bookmark.Posting === false)
            {
                if ((bookmark.BookmarkKey === 0 && bookmark.RowState === $rootScope.RowState.Added)
                    || (bookmark.BookmarkKey !== 0 && bookmark.RowState === $rootScope.RowState.Deleted))
                {
                    AccessInformation.Bookmark[index].Posting = true;

                    $http({
                        url: $rootScope.masterWebApiUrl + "Bookmark",
                        method: "POST",
                        jsonp: false,
                        dataType: "json",
                        data: {
                            BookmarkKey: thisBookmark.BookmarkKey,
                            ClientKey: thisBookmark.ClientKey,
                            UserKey: thisBookmark.UserKey,
                            Name: thisBookmark.Name,
                            MenuKey: thisBookmark.MenuKey,
                            Content: JSON.stringify(thisBookmark.Content),
                            Active: thisBookmark.Active,
                            CreatedBy: thisBookmark.CreatedBy,
                            CreatedDate: thisBookmark.CreatedDate,
                            UpdatedBy: thisBookmark.UpdatedBy,
                            UpdatedDate: thisBookmark.UpdatedDate,
                            CreatedByCode: thisBookmark.CreatedByCode,
                            UpdatedByCode: thisBookmark.UpdatedByCode,
                            RowState: thisBookmark.RowState
                        },
                        headers: { 'Content-Type': 'application/json' }
                    }).then(function (data)
                    {
                        if (thisBookmark.RowState === $rootScope.RowState.Added)
                        {
                            AccessInformation.Bookmark[index].BookmarkKey = data.data.BookmarkKey;
                            AccessInformation.Bookmark[index].ClientKey = data.data.ClientKey;
                            AccessInformation.Bookmark[index].UserKey = data.data.UserKey;
                            AccessInformation.Bookmark[index].CreatedBy = data.data.CreatedBy;
                            AccessInformation.Bookmark[index].CreatedDate = data.data.CreatedDate;
                            AccessInformation.Bookmark[index].UpdatedBy = data.data.UpdatedBy;
                            AccessInformation.Bookmark[index].UpdatedDate = data.data.UpdatedDate;
                            AccessInformation.Bookmark[index].CreatedByCode = data.data.CreatedByCode;
                            AccessInformation.Bookmark[index].UpdatedByCode = data.data.UpdatedByCode;
                            AccessInformation.Bookmark[index].Posting = false;

                            if (AccessInformation.Bookmark[index].RowState !== $rootScope.RowState.Deleted)
                            {
                                //still exist
                                AccessInformation.Bookmark[index].RowState = $rootScope.RowState.Unchanged;
                            }
                            else
                            {
                                //get deleted already
                                AccessInformation.Bookmark[index].RowState = $rootScope.RowState.Deleted;
                                //trigger again
                                _postBookmark();
                            }
                        }
                        else if (thisBookmark.RowState === $rootScope.RowState.Deleted)
                        {
                            AccessInformation.Bookmark[index].BookmarkKey = 0;
                            AccessInformation.Bookmark[index].Posting = false;
                            AccessInformation.Bookmark[index].RowState = $rootScope.RowState.Deleted;
                        }
                    }, function () { });
                }
            }
        });
    };

    //post access information to server
    displayServiceFactory.postFormUsage = signalRService.postFormUsage;

    //update AutoComplete list
    displayServiceFactory.updateAutocomplete = function ()
    {
        broadcastService.broadcast("assignAutocomplete", true);

        return AccessInformation.Autocomplete.deepClone();
    };

    displayServiceFactory.refresh = function ()
    {
        let defer = $q.defer();

        displayServiceFactory.loadDisplayInformation().then(function ()
        {
            displayServiceFactory.loadAccessInformation().then(function ()
            {
                //refresh all loaded stuff
                broadcastService.broadcast("refreshDisplayInfo", true);

                defer.resolve();
            }, function ()
            {
                defer.reject();
            });
        }, function ()
        {
            defer.reject();
        });

        return defer.promise;
    };

    $rootScope.$on("receiveUIUpdateSignal", function (data)
    {
        //don't hang the UI
        setTimeout(function ()
        {
            displayServiceFactory.refresh().catch(function ()
            {
                //well nothing much you can do
            });
        }, 0);
    });

    return displayServiceFactory;
}]);